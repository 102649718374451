/**
 * Your variable overrides in Bootstrap
 **/

// Color system
$primary: #0D6EFD !default;
$secondary: #6C757D !default;
$success: #198754 !default;
$info: #0DCAF0 !default;
$warning: #FFC107 !default;
$danger: #DC3545 !default;
$light: #F8F9FA !default;
$dark: #212529 !default;
$white: #ffffff;
$black: #000000;

// Color custom
$azul: #081D31 !default;
$celeste: #5CB6CC !default;
$verde: #6EA63B !default;
$gris-ccc: #CCCCCC !default;
$gris-444: #444444 !default;
$gris-777: #777777 !default;
$gris-eee: #EEEEEE !default;

$gris-text-muted: #999999 !default;

$theme-colors: (
   "primary": $primary,
   "secondary": $secondary,
   "success": $success,
   "info": $info,
   "warning": $warning,
   "danger": $danger,
   "light": $light,
   "dark": $dark,
   "negro": #000000,
   "blanco": #FFFFFF,
   "azul": $azul,
   "celeste": $celeste,
   "verde": $verde,
   "gris-ccc": $gris-ccc,
   "gris-444": $gris-444,
   "gris-777": $gris-777,
   "gris-eee": $gris-eee
) !default;

// Options
$enable-caret:                true !default;
$enable-rounded:              true !default;
$enable-shadows:              false !default;
$enable-gradients:            false !default;
$enable-transitions:          true !default;
$enable-reduced-motion:       true !default;
$enable-smooth-scroll:        false !default;
$enable-grid-classes:         true !default;
$enable-button-pointers:      true !default;
$enable-rfs:                  true !default;
$enable-validation-icons:     true !default;
$enable-negative-margins:     true !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities:  true !default;

// Spacing
$spacer:  1rem !default;
$spacers: (
   0: 0,
   1: $spacer * .25,
   2: $spacer * .5,
   3: $spacer,
   4: $spacer * 1.5,
   5: $spacer * 3,
   6: $spacer * 4,
   7: $spacer * 5,
   8: $spacer * 6,
   9: $spacer * 7,
) !default;

// Body
$body-color: #827B7B;

// Links
$link-color:                        $verde !default;
$link-decoration:                   none !default;
$link-shade-percentage:             20% !default;
$link-hover-color:                  shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration:             null !default;

$stretched-link-pseudo-element:     after !default;
$stretched-link-z-index:            1 !default;

// Paragraphs
$paragraph-margin-bottom:           1.5rem !default;

// Grid columns
$grid-columns:                12 !default;
$grid-gutter-width:           40px !default;
$grid-row-columns:            6 !default;

// Components
// border-radius-variables
$border-radius:               17px !default;
$border-radius-sm:            .2rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-pill:          50rem !default;

$component-active-color:      $white !default;
$component-active-bg:         $azul !default;

$transition-base:             all .3s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;

// Typography
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
$font-family-base: 'Poppins', sans-serif;
$font-family-sans-serif: 'Poppins', sans-serif;

$font-size-root:              null !default;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$line-height-base:            1.6 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              2 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base * .875!default;

$lead-font-size:              $font-size-base * 1.25 !default;
$lead-font-weight:            300 !default;
$small-font-size:             12px !default;
$sub-sup-font-size:           .75rem !default;

$text-muted:                  $gris-text-muted !default;

// Tables

// Buttons + Forms
$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         .75rem !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:         .25rem !default;
$input-btn-focus-color-opacity: .25 !default;
$input-btn-focus-color:         rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur:          0 !default;
$input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      $font-size-sm !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;

$input-btn-border-width:      1px !default;

// Buttons
$btn-padding-y:               12px !default;
$btn-padding-x:               24px !default;
$btn-font-size:               14px !default;
$btn-line-height:             1 !default;
$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            10px !default;
$btn-padding-x-sm:            16px !default;
$btn-font-size-sm:            12px !default;

$btn-padding-y-lg:            20px !default;
$btn-padding-x-lg:            50px !default;
$btn-font-size-lg:            16px !default;

$btn-link-color:              $link-color !default;
$btn-link-hover-color:        $link-hover-color !default;
$btn-link-disabled-color:     $gris-text-muted !default;

$btn-border-width:            2px !default;

$btn-border-radius:           50px !default;
$btn-border-radius-sm:        50px !default;
$btn-border-radius-lg:        50px !default;

// Forms
$form-text-margin-top:                  .25rem !default;
$form-text-font-size:                   $small-font-size !default;
$form-text-font-style:                  null !default;
$form-text-font-weight:                 null !default;
$form-text-color:                       $text-muted !default;

$form-label-margin-bottom:              .5rem !default;
$form-label-font-size:                  null !default;
$form-label-font-style:                 null !default;
$form-label-font-weight:                null !default;
$form-label-color:                      null !default;

// Navs
$nav-link-padding-y:                .5rem !default;
$nav-link-padding-x:                1rem !default;
$nav-link-font-size:                null !default;
$nav-link-font-weight:              null !default;
$nav-link-color:                    $link-color !default;
$nav-link-hover-color:              $link-hover-color !default;
$nav-link-transition:               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !default;
$nav-link-disabled-color:           $gris-text-muted !default;

$nav-tabs-border-color:             $gris-text-muted !default;
$nav-tabs-border-width:             1px !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  $gris-text-muted !default;
$nav-tabs-link-active-color:        $gris-text-muted !default;
$nav-tabs-link-active-bg:           white !default;
$nav-tabs-link-active-border-color: $gris-text-muted !default;

$nav-pills-border-radius:           $border-radius !default;
$nav-pills-link-active-color:       $component-active-color !default;
$nav-pills-link-active-bg:          $component-active-bg !default;

// Navbar
$navbar-padding-y:                  $spacer * .5 !default;
$navbar-padding-x:                  null !default;
$navbar-nav-link-padding-x:         .5rem !default;
$navbar-brand-font-size:            $font-size-lg !default;

$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5 !default;
$navbar-brand-margin-end:           1rem !default;

$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
$navbar-toggler-border-radius:      $btn-border-radius !default;
$navbar-toggler-transition:         box-shadow .15s ease-in-out !default;

$navbar-dark-color:                 rgba($white, .55) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='{$azul}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  $azul !default;

$navbar-light-color:                rgba($black, .55) !default;
$navbar-light-hover-color:          rgba($black, .7) !default;
$navbar-light-active-color:         rgba($black, .9) !default;
$navbar-light-disabled-color:       rgba($black, .3) !default;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='{$azul}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: $azul !default;

$navbar-light-brand-color:                $navbar-light-active-color !default;
$navbar-light-brand-hover-color:          $navbar-light-active-color !default;
$navbar-dark-brand-color:                 $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color:           $navbar-dark-active-color !default;

// Dropdowns

// Pagination
$pagination-padding-y:              .375rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;

$pagination-color:                  $link-color !default;
$pagination-bg:                     $white !default;
$pagination-border-radius:          $border-radius !default;
$pagination-border-color:           $gris-text-muted !default;

$pagination-focus-color:            $link-hover-color !default;
$pagination-focus-bg:               $gris-text-muted !default;
$pagination-focus-outline:          0 !default;

$pagination-hover-color:            $link-hover-color !default;
$pagination-hover-bg:               $gris-text-muted !default;
$pagination-hover-border-color:     $gris-text-muted !default;

$pagination-active-color:           $component-active-color !default;
$pagination-active-bg:              $component-active-bg !default;
$pagination-active-border-color:    $pagination-active-bg !default;

$pagination-disabled-color:         $gris-text-muted !default;
$pagination-disabled-bg:            $white !default;
$pagination-disabled-border-color:  $gris-text-muted !default;

$pagination-border-radius-sm:       $border-radius-sm !default;
$pagination-border-radius-lg:       $border-radius-lg !default;

// Cards

// Accordion

// Tooltips

// Popovers

// Toasts

// Badges

// Modals

// Alerts

// Progress bars

// List group

// Image thumbnails
$thumbnail-padding:                 .25rem !default;
$thumbnail-bg:                      white !default;
$thumbnail-border-width:            1px !default;
$thumbnail-border-color:            $gris-text-muted !default;
$thumbnail-border-radius:           $border-radius !default;

// Figures
$figure-caption-font-size:          $small-font-size !default;
$figure-caption-color:              $gris-text-muted !default;

// Breadcrumbs
$breadcrumb-font-size:              null !default;
$breadcrumb-padding-y:              0 !default;
$breadcrumb-padding-x:              0 !default;
$breadcrumb-item-padding-x:         .5rem !default;
$breadcrumb-margin-bottom:          1rem !default;
$breadcrumb-bg:                     null !default;
$breadcrumb-divider-color:          $gris-text-muted !default;
$breadcrumb-active-color:           $gris-text-muted !default;
$breadcrumb-divider: url("./../img/svg/chevron-right.svg");
//$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='white'/%3E%3C/svg%3E");
$breadcrumb-divider-flipped:        $breadcrumb-divider !default;
$breadcrumb-border-radius:          null !default;

// Carousel

// Spinners

// Close

// Offcanvas

// Code


/**
* Include all of Bootstrap
**/
@import "bootstrap/scss/bootstrap";

// compile file
